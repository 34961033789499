<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        教师管理
      </template>
      <template #input>
        <a-input placeholder="请输入关键字" v-model="queryParams.keyword" allow-clear/>
        <a-button class="all_boder_btn btn" @click="onSearch" type="primary">搜索</a-button>
        <a-button class="all_boder_btn btn" @click="toDetail(1)">新建</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table 
        class="table-template"
        :rowKey="item=>item.teacherId"
        :columns="columns" 
        :loading="loading"
        :data-source="tableData" 
        :locale="{emptyText: '暂无数据'}" 
        @change="onPage"
        :pagination="{
          total:total,
          current:queryParams.pageNum,  
          defaultpageSize:queryParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
            return `共${total}条`
          }
        }">
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center;">
              {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
            </div>
          </template>
          <template slot="operation" slot-scope="item, row">
            <div class="btn_router_link">
              <a @click="toDetail(2,row.teacherId)">编辑</a>
              <span>|</span>
              <a @click="deleteData(row.teacherId)">删除</a>
            </div>
          </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "职称",
    align:'center',
    dataIndex: "title"
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
import HeaderBox from '@/components/HeaderBox.vue'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      state: 1, // 1新建，2编辑
      columns, // 表头
      tableData:[], // 列表数据
      loading:false, // 列表加载
      total: 0, // 总数量
      queryParams: {
        keyword:'',
        pageNum: 1, //页码
        pageSize: 10, // 页数
      }
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getManageList()
    },
    // 高级检索
    onSearch(){
      this.queryParams.pageNum = 1
      this.getManageList()
    },
    // 跳详情页
    toDetail(operation,id) {
      this.$router.push({ path: "/admin/BasicConfig/TeacherManagementDetails", query: { operation:operation,id: id } })
    },
    // 删除标签
    deleteData(id) {
      let _this = this
      this.$confirm({
        title: '确定删除该数据吗?',
        okText: '确认',
        onOk() {
          _this.$ajax({
            url: '/hxclass-management/teacher/' + id,
            method: "DELETE",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success('删除成功');
              _this.getManageList()
            }
          })
        },
        onCancel() {},
      });
    },
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/teacher/",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getManageList()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect{
    padding-left: 6px;
  }
}
.rows{
  margin-bottom: 20px;
  .left{
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
    &::after{
      content: '：';
    }
  }
}
</style>
