var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 教师管理 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入关键字","allow-clear":""},model:{value:(_vm.queryParams.keyword),callback:function ($$v) {_vm.$set(_vm.queryParams, "keyword", $$v)},expression:"queryParams.keyword"}}),_c('a-button',{staticClass:"all_boder_btn btn",attrs:{"type":"primary"},on:{"click":_vm.onSearch}},[_vm._v("搜索")]),_c('a-button',{staticClass:"all_boder_btn btn",on:{"click":function($event){return _vm.toDetail(1)}}},[_vm._v("新建")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item){ return item.teacherId; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{emptyText: '暂无数据'},"pagination":{
        total:_vm.total,
        current:_vm.queryParams.pageNum,  
        defaultpageSize:_vm.queryParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
          return ("共" + total + "条")
        }
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.queryParams.pageNum - 1) * _vm.queryParams.pageSize + i + 1)+" ")])]}},{key:"operation",fn:function(item, row){return [_c('div',{staticClass:"btn_router_link"},[_c('a',{on:{"click":function($event){return _vm.toDetail(2,row.teacherId)}}},[_vm._v("编辑")]),_c('span',[_vm._v("|")]),_c('a',{on:{"click":function($event){return _vm.deleteData(row.teacherId)}}},[_vm._v("删除")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }